import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const AutoService = () => {
  const headingbanner = {
    title: `Trackier`,
    content: `A platform to optimise marketing performance`,
  };

  const data = {
    images: ["trackier-1.webp", "trackier-2.webp"],
    firstAlt: "UI UX Design to track realtime marketing performance",
    firstTitle: "Dashboard",
    secondAlt: "UI Screen To Ceate and View Widgets",
    secondTitle: "Widget Builder",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: [
          "User Research",
          "UI UX Designing",
        ],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `There were several potential challenges we encountered while redesigning this platform. Firstly, the platform suffered from a cluttered and disorganized interface, making it difficult for users to navigate and locate essential features. `,
          `This led to frustration and decreased productivity for marketers trying to access critical data swiftly.`,
          `Additionally, the platform lacked intuitive information architecture and clear hierarchies due to which users struggled to understand the flow of tasks and the relationships between different elements.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `Firstly, conducting thorough user research and usability testing was crucial. a streamlined and intuitive information architecture could have been designed, ensuring clear navigation and logical flow of tasks. `,
          `Consistency in design elements, such as buttons, icons, and color schemes was established to create a cohesive visual language. `,
          `Also, we designed the platform in such a way that offers real-time, accurate tracking, robust fraud detection and detailed analytics. `,
          `This would help users to to make data-driven decisions, allocate rewards fairly, prevent fraud, and optimize their affiliate marketing campaigns effectively.`
        ],
      },
    ],
    image: ["trackier-case-study.webp"],
    firstAlt: 'UI UX Components To Design Affiliate Marketing Platform',
    firstTitle: 'Design Components',
  };

  const projectData = {
    title: "Affiliate Insights Hub",
    para: [
      `Get a real-time snapshot of all your campaigns, with actionable insights at your fingertips.`,
    ],
    content: {
      imageAlt: 'UI UX Components To Design Affiliate Marketing Platform',
      imageTitle: 'Design Components',
      secondAlt: 'UI UX Design For Custom Dashboard',
      secondTitle: 'Custom Dashboard',
      image: ["trackier-content-1.webp"],
      text: `Monitor performance, automate tasks, and make data-driven decisions effortlessly from one intuitive screen.`,
    },
    content2: {
        image: ["trackier-content-2.webp"],
        para: [
            `The user-centric design ensures a seamless experience, allowing for effortless navigation through key performance indicators, conversion rates, and customer demographics. `,
            `Interactive charts and graphs dynamically display trends, enabling swift decision-making and strategic adjustments.`
        ]
      },
  };

  const projectData2 = {
    title: `Unlocking Seamless Navigation`,
    para: [
      `Create, manage, and optimize deep links effortlessly to drive targeted user engagement and enhance the effectiveness of your marketing campaigns.`,
    ],
    content: {
      image: ["trackier-content-3.webp"],
      imageAlt: 'Manage Deep Links',
      imageTitle: 'Deep Links',
    },
  };

  const projectData3 = {
    title: "Intelligent engagement & management",
    para: [
      `It provides a powerful suite of tools and features to efficiently plan, execute, and monitor your marketing initiatives across diverse channels. `,
      `It gives a comprehensive view of customer engagement across various touchpoints, including social media, email marketing, website visits, and more. `,
      `Monitor audience responses, track conversion rates, and gather valuable customer feedback in real-time.`
    ],
    content: {
      image: [
        "trackier-case-study-six.webp",
        "trackier-case-study-two.webp",
        "trackier-case-study-three.webp",
        "trackier-case-study-four.webp",
        "trackier-case-study-five.webp",
        "trackier-case-study-seven.webp",
      ],
      firstAlt: 'UI Design To Manage Smart Banners',
      firstTitle: 'Smart Banners',
      secondAlt: 'UI UX Design To Create New Banner Group',
      secondTitle: 'Create New Banner',
      thirdAlt: 'Dashboard To Track Engagement Activities',
      thirdTitle: 'Activity Tracker',
      fourthAlt: 'UI UX Design to Track Engagement Logs',
      fourthTitle: 'Engagement Logs',
      fifthAlt: 'UI UX Design to Manage And Create Events',
      fifthTitle: 'Manage Events',
      sixthAlt: 'Settings',
      sixthTitle: 'Settings',
    },
  };

  const conclusionData = [
    {
      para: [
        `We embarked on a mission to simplify complexities and empower general users to harness the full potential of their marketing efforts.`,
        `Our dedication to user-centric design has resulted in an interface that is intuitive, efficient, and accessible to marketers of all levels of expertise, making it a tool that empowers every user to make data-driven decisions confidently.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={39} />
      </div>
    </Layout>
  );
};

export default AutoService;

export const Head = () => (
  <Seo title="Trackier- Affiliate Marketing Platform | Octet Design Studio" description="We created a user-centric interface for the users to harness their full marketing potential with our services on User Research and UI UX Design." />
)